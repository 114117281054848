@import '../../node_modules/flatpickr/dist/flatpickr.css';

.flatpickr-day.selected {
  background: $selected-date;
  background-color: $selected-date;
  border-radius: 0;
  font-weight: $font-weight-bold;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.today:hover {
  background-color: $date-disabled;
  background: $date-disabled;
  border-radius: 0;
  color: $date-disabled-text;
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  max-height: fit-content;
}

.flatpickr-calendar {
  display: none;
  width: calc(100% - 55px);
  padding: 30px 12px;

  @media (min-width: $screen-sm-min) {
    max-width: 515px;
    width: 100%;
  }

  @media (min-width: $breakpoint-laptop-l) {
    grid-template-areas:
      'title hours'
      'container hours';
    grid-template-rows: 50px 100% 100%;
    grid-template-columns: 50% 50%;
    max-width: 100%;
    padding: 30px 40px;
    width: auto;

    &--with-hours {
      display: block;
      width: 1189px;

      @media (min-width: $breakpoint-laptop-l) {
        display: grid !important;
      }
    }
  }
}

.flatpickr-months {
  grid-area: title;
}

.flatpickr-innerContainer {
  grid-area: container;

  @media (min-width: $screen-m-min) {
    width: 506px;
  }
}

.flatpickr-time-wrapper {
  grid-area: hours;
}

.flatpickr-days,
.flatpickr-rContainer,
.dayContainer {
  width: 100%;
  max-width: 100%;
  min-width: auto;
}

.flatpickr-day:hover {
  border: 1px solid $selected-date;
  border-color: $selected-date;
  background-color: $color-secondary;
  background: $color-secondary;
  color: $selected-date;
  border-radius: 0;
}

.flatpickr-day {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 70px;
  font-size: 12px;
  word-break: keep-all;

  @media (min-width: $breakpoint-laptop-l) {
    padding: 35px;
    font-size: 14px;
  }
}

.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day {
  border: 1px solid $date-border;
  border-color: $date-border;
  border-radius: 0;
}

.flatpickr-current-month {
  text-align: left;
  left: 0;
  margin-bottom: 12px;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  top: 30px;
  right: 12px;

  @media (min-width: $screen-m-min) {
    right: calc(55% - 40px);
    transform: translateX(-50%);
  }
}

.flatpickr-months .flatpickr-prev-month {
  right: 30px;
  top: 30px;
  left: inherit !important;

  @media (min-width: $screen-m-min) {
    right: calc(55% - 20px);
    transform: translateX(-50%);
    top: 30px;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  width: fit-content;
}

.flatpickr-time {
  display: none;
}

.flatpickr-label {
  border-radius: 4px;
  border: 1px solid $date-border;
  padding: 12px 24px;
  font-weight: $font-weight-semibold;
  cursor: pointer;
  height: fit-content;

  &[data-selected] {
    background-color: $selected-date;
    color: $color-secondary;
  }

  input {
    display: none;
  }
}

.flatpickr-label:hover {
  border: 1px solid $selected-date;
  background-color: $color-secondary;
  color: $selected-date;
  border-radius: 0;
}

.flatpickr-time-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  width: 100%;

  &[data-hidden='true'] {
    display: none;
  }

  @media (min-width: $screen-sm-min) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $screen-m-min) {
    gap: 24px;
  }
}

.flatpickr-time-title {
  color: $datepicker-title;
  font-weight: $font-weight-bold;
  text-align: left;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 12px;

  @media (min-width: $screen-m-min) {
    margin-bottom: 0 0 20px 0;
  }
}

.flatpickr-time-title__date-hour {
  color: $color-neutral;
  text-align: left;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
}

.flatpickr-time__titles-wrapper {
  grid-area: titles-wrapper;
}
