// general settings
//! General settings
$full-width: 100vw;
$wrapper-width: 1280px;
$container-width: 1280px;

//! Colors
$color-primary: var(--primary);
$color-primary-dark: var(--primary-dark);
$color-primary-semi-dark: var(--primary-semi-dark);
$color-primary-semi-light: var(--primary-semi-light);
$color-primary-very-light: var(--primary-very-light);
$color-primary-transparent: var(--primary-transparent);
$color-primary-very-transparent: var(--primary-very-transparent);
$color-white-semi-transparent: rgba(255, 255, 255, 0.3);
$color-secondary: #ffff;
$color-neutral: #69666c;
$color-accent: #f4f4f4;
$color-gray-lt: #d9d9d9;
$color-gray-md: #848484;
$color-gray-disabled: #e8e8e8;
$color-gray-dk: #3c3c3c;
$color-peacock-blue: #004d9b;
$color-error: #eb1f1f;
$color-success: #54c46a;
$color-black: black;
$color-helper-text: #b4b4b4;
$form-title-black: #333;
$form-subtitle-black: #1e1e1e;
$selected-date: #0f6ea8;
$date-disabled: #ececec;
$date-disabled-text: #a6a4a7;
$date-border: #f0f0f0;
$datepicker-title: #2f2f2f;
$dirty-gray: #878787;

$background-lt: #f4f4f4;
$color-text: $color-gray-dk;
$color-shadow-box: rgba(0, 0, 0, 0.1);

//! Fonts and font-styles
$font-primary: 'Merriweather';
$font-secondary-bold: 'Lato';
$font-secondary-heavy: 'Lato';
$font-secondary-semibold: 'Lato';
$font-secondary-regular: 'Lato';

$font-size-title: 40px;
$font-size-title-credit: 38px;
$font-size-title-tablet: 40px;
$font-size-subtitle: 27px;
$font-size-title-mobile: 22px;
$font-size-buttons: 18px;
$font-size-variated-text: 17px;
$font-size-normal-text: 16px;
$font-size-small-text: 14px;
$font-size-helper-text: 12px;
$font-size-portlet-title: 20px;
$font-size-portlet-title-mobile: 18px;

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-regular: 500;
$font-weight-thin: 400;

$line-height-desktop: 47px;
$line-height-large: 38px;
$line-height-mobile: 22px;
$line-height-sm: 24px;
$line-height-small: 17px;
$line-height-helper-text: 18px;

//! Spacing sizes
$spacing-sizes: 10px, 15px, 20px, 25px, 30px, 40px, 50px, 80px, 120px, 160px;

// ! Breakpoints
$screen-m-min: 1024px;
$screen-sm-max: 991px;
$screen-sm-min: 768px;

$breakpoint-wrapper: 1280px;
$breakpoint-laptop-l: 1200px;
$breakpoint-laptop-m: 1100px;
$breakpoint-laptop-s: 1000px;
$breakpoint-tablet-l: 800px;
$breakpoint-tablet-m: 700px;
$breakpoint-tablet-s: 600px;
$breakpoint-phone-l: 500px;
$breakpoint-phone-m: 400px;
$breakpoint-phone-s: 300px;
